<script>
  import AddMeetingsLogo from "$lib/AddMeetingsLogo.svelte"
  import AddMeetingsText from "$lib/AddMeetingsText.svelte"
  import Navbar from "flowbite-svelte/Navbar.svelte"
  import NavBrand from "flowbite-svelte/NavBrand.svelte"
  import NavContainer from "flowbite-svelte/NavContainer.svelte"
  import NavHamburger from "flowbite-svelte/NavHamburger.svelte"
  import NavLi from "flowbite-svelte/NavLi.svelte"
  import NavUl from "flowbite-svelte/NavUl.svelte"
</script>

<Navbar class="bg-transparent">
  <NavContainer class="bg-transparent">
    <div class="flex flex-nowrap flex-auto">
      <NavBrand href="/" class="flex items-center gap-2" title="AddMeetings">
        <AddMeetingsLogo class="w-8 h-8 stroke-current" />
        <AddMeetingsText class="w-auto h-8 ml-1" />
        <span class="sr-only">AddMeetings</span>
      </NavBrand>
    </div>
    <div class="flex sm:order-2">
      <NavHamburger class="block sm:hidden" />
    </div>
    <NavUl ulClass="flex flex-col py-2.5 px-4 mt-4 sm:flex-row sm:space-x-4 rtl:space-x-reverse sm:mt-0 sm:text-sm sm:font-medium text-center">
      <NavLi class="md:py-2" href="/about">About</NavLi>
      <NavLi class="md:py-2" href="/for-buyers">For Buyers</NavLi>
      <NavLi class="md:py-2" href="/for-sales">For Sales</NavLi>
      <!-- <NavLi class="md:py-2" href="/explore">Explore</NavLi> -->
      <NavLi class="flex items-center justify-center gap-2 bg-[#536aff] text-white hover:text-white md:px-4 md:py-2 rounded-full hover:hover:bg-[#536aff]/90 md:hover:bg-[#536aff]/90 md:hover:text-white transition-colors" href="https://app.addmeetings.com/login">
        Try for Free
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.9355 8.8H0V7.2H12.9355L6.85947 1.124L8 0L16 8L8 16L6.85947 14.876L12.9355 8.8Z" fill="#F6F8FF"/>
        </svg>     
      </NavLi>
      <NavLi class="md:py-2 md:px-4 rounded-full md:border border-black" href="https://app.addmeetings.com/login">Log in</NavLi>
    </NavUl>
  </NavContainer>
</Navbar>
