<script lang="ts">
  // let email = '';
  // let status: 'idle' | 'loading' | 'success' | 'error' = 'idle';
  // let message = '';

  // async function handleSubmit(e: SubmitEvent) {
  //   e.preventDefault();
  //   status = 'loading';

  //   try {
  //     const response = await fetch('/api/newsletter', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ email }),
  //     });

  //     const data = await response.json();

  //     if (!response.ok) {
  //       throw new Error(data.error || 'Failed to subscribe');
  //     }

  //     status = 'success';
  //     message = data.message;
  //     email = '';

  //     // Reset status after 3 seconds
  //     setTimeout(() => {
  //       status = 'idle';
  //       message = '';
  //     }, 3000);

  //   } catch (error) {
  //     status = 'error';
  //     message = error instanceof Error ? error.message : 'Something went wrong';

  //     // Reset status after 3 seconds
  //     setTimeout(() => {
  //       status = 'idle';
  //       message = '';
  //     }, 3000);
  //   }
  // }
</script>

<footer class="bg-[#f8fafc] py-16 border-t border-gray-200">
  <div class="container mx-auto px-4">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-12 max-w-6xl mx-auto">
      <!-- Newsletter Subscription -->
      <!--<div class="space-y-4">
        <div>
          <h3 class="text-2xl font-bold mb-4">Subscribe</h3>
          <p class="text-[#8a8c94] mb-4">
            Subscribe to our newsletter to get exclusive updates on our new features,
          </p>
        </div>
        
        <form on:submit={handleSubmit}>
          <div class="flex flex-wrap gap-2">
            <input
              type="email"
              placeholder="Enter your email"
              name="email"
              bind:value={email}
              class="flex-1 px-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#536aff] focus:border-transparent"
              required
              disabled={status === 'loading'}
            />
            <button
              type="submit"
              class="bg-[#070811] text-white px-6 py-2 rounded-lg hover:bg-[#070811]/90 transition-colors"
              disabled={status === 'loading'}
            >
              {#if status === 'loading'}
                <span class="flex items-center gap-2">
                  <svg class="animate-spin h-4 w-4" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="none"/>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                  </svg>
                  Subscribing...
                </span>
              {:else}
                Subscribe
              {/if}
            </button>
          </div>

          {#if message}
            <p class={`text-sm ${status === 'success' ? 'text-green-600' : 'text-red-600'}`}>
              {message}
            </p>
          {/if}
        </form>
      </div>-->

      <!-- Navigation Links -->
      <div class="space-y-4">
        <a href="httsp://app.addmeetings.com/login" class="block hover:text-[#536aff] transition-colors">
          Register / Sign Up
        </a>
        <a href="/for-buyers" class="block hover:text-[#536aff] transition-colors">
          For Decision Makers
        </a>
        <a href="/for-sales" class="block hover:text-[#536aff] transition-colors">
          For Sales People
        </a>
        <a href="/about" class="block hover:text-[#536aff] transition-colors">
          About Us
        </a>
        <a href="/privacy-policy" class="block hover:text-[#536aff] transition-colors">
          Privacy Policy
        </a>
        <a href="/terms-and-conditions" class="block hover:text-[#536aff] transition-colors">
          Terms of Service
        </a>
      </div>

      <!-- Social Links -->
      <div class="space-y-4">
        <!-- <a href="/faq" class="block hover:text-[#536aff] transition-colors">
          FAQ
        </a> -->
        
        <a 
          href="https://www.youtube.com/@AddMeetings" 
          target="_blank" 
          rel="noopener noreferrer"
          class="flex items-center gap-2 hover:text-[#536aff] transition-colors"
        >
          <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
          </svg>
          Youtube
        </a>
        
        <!-- <a 
          href="https://instagram.com" 
          target="_blank" 
          rel="noopener noreferrer"
          class="flex items-center gap-2 hover:text-[#536aff] transition-colors"
        >
          <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
          </svg>
          Instagram
        </a> -->
        
        <a 
          href="https://www.linkedin.com/company/add-meetings/" 
          target="_blank" 
          rel="noopener noreferrer"
          class="flex items-center gap-2 hover:text-[#536aff] transition-colors"
        >
          <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
          </svg>
          Linkedin
        </a>
        
        <!-- <a 
          href="https://twitter.com" 
          target="_blank" 
          rel="noopener noreferrer"
          class="flex items-center gap-2 hover:text-[#536aff] transition-colors"
        >
          <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
          </svg>
          X (formally Twitter)
        </a> -->
      </div>
    </div>
    <p class="mt-4 text-center text-sm text-[#8a8c94]">
      Copyright &copy; 2025 - Add Meetings AS
    </p>
  </div>
</footer>

<style>
  /* Add smooth transitions */
  a {
    -webkit-tap-highlight-color: transparent;
  }
  
  /* Ensure proper focus states for accessibility */
  a:focus-visible {
    outline: 2px solid #536aff;
    outline-offset: 2px;
  }
</style>
